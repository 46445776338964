// AuthContext.js
import React, { createContext, useEffect, useState } from 'react';
import axiosClient from 'helpers/hooks/axiosClient';
import { getStorageToken } from 'helpers/storage';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const token = getStorageToken()
  const cred = JSON.parse(localStorage.getItem('sparefoot'));
  const [sparefoot, setSparefoot] = useState(cred)
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [auth, setAuth] = useState(localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null)
  const [portfolio, setPortfolio] = useState(null)

  useEffect(() => {
    if (!auth) {
      localStorage.clear();
      setIsAuthenticated(null);
    }
  }, [])

  const setAuthCred = (user) => {
    if (user) {
      setAuth(user)
      localStorage.setItem('user', JSON.stringify(user))
    }
  }

  const setPortfolioContext = (portfolio) => {
    setPortfolio(portfolio)
    localStorage.setItem('portfolio', JSON.stringify(portfolio))
  }

  const setSpareAuth = async (bool) => {
    localStorage.setItem('sparefoot', bool)
    setSparefoot(bool)
  }

  const login = async (token, user, isRememberMe) => {
    if (isRememberMe) {
      localStorage.setItem('token', token);
    } else {
      sessionStorage.setItem('token', token);
    }
    localStorage.setItem('user', JSON.stringify(user))
    if (user?.sparefoot_u) {
      setSpareAuth(true)
    } else {
      setSpareAuth(false)
    }
    setAuthCred(user)
    axiosClient.get('/portfolio/user').then((response) => {
      const portfolio = response.data.result
      setPortfolio(portfolio)
      localStorage.setItem('portfolio', JSON.stringify(portfolio))
    })
    setIsAuthenticated(!!token)
  }

  const logout = async () => {
    localStorage.clear()
    sessionStorage.clear()
    setIsAuthenticated(null)
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        auth,
        setAuthCred,
        sparefoot,
        setSpareAuth,
        portfolio,
        setPortfolioContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
