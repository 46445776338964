import React, { useEffect, useState, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosClient from '../../helpers/hooks/axiosClient';
import FormControl from '@mui/material/FormControl';
import Typography from "@mui/material/Typography";
import MDButton from 'components/MDButton';
import TextField from '@mui/material/TextField';
import { AuthContext } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { useRollbar } from '@rollbar/react';

const AuthModal = ({ modalOpen }) => {
  const navigate = useNavigate();
  const rollbar = useRollbar();
  const { auth, setSpareAuth } = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [buttonValue, setButtonValue] = useState('Connect')

  const setDataToUsers = () => {
    axiosClient.post('/users/sparefoot', { sparefoot_u: email, sparefoot_p: password, email: auth.email }).catch((e) => {
      console.error(e);
    });
  }

  const handleSignIn = () => {
    setButtonValue('Connecting...')
    axiosClient.post('/sparefoot/login', { email: email, password: password }).then((result) => {
      setTimeout(() => {
        navigate('/loading')
      }, 2000);
      setSpareAuth(true)
      setDataToUsers();
      setButtonValue('Success!')
      setError(false)
    }).catch(() => {
      setError(true)
      setButtonValue('Connect')
      rollbar.warning("Failed to login to SpareFoot");
    })
  };

  return (
    <Dialog open={modalOpen} >
      <DialogTitle textAlign="center">
        Please Enter Your SpareFoot Credentials</DialogTitle>
      <DialogContent style={{ padding: '20px 30px 20px 30px' }}>
        <FormControl>
          <div style={{ textAlign: "center" }}>
            <TextField style={{ marginBottom: '20px' }} fullWidth label="Email" placeholder="MySpareFoot Email" error={error} value={email} onChange={(e) => setEmail(e.target.value)} />
            <TextField style={{ marginBottom: '20px' }} fullWidth label="Password" type="password" placeholder="MySpareFoot Password" error={error} value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <DialogContentText>
            To start using BigFoot, you must enter your SpareFoot credentials. Visit my.sparefoot.com to find this information. If you have already entered your SpareFoot credentials, your MySpareFoot login information has changed and must be updated here.
          </DialogContentText>
        </FormControl>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <MDButton variant="outlined" color="error" onClick={handleSignIn}>{buttonValue}</MDButton>
      </DialogActions>
      {error && <Typography variant="h6" component="h6" align="center" color="error" style={{ margin: '20px 0' }}>Failed to connect. Please verify your email and password and try again.</Typography>}
    </Dialog>
  );
};

export default AuthModal;
