import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import GraphicEqOutlinedIcon from '@mui/icons-material/GraphicEqOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SellOutlined from '@mui/icons-material/SellOutlined';
import { Container } from '@mui/material';
import MDButton from "components/MDButton";
import { TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import axiosClient from '../../helpers/hooks/axiosClient';
import { portfolioDataAtom, facilityDataAtom } from 'store/data';
import { useAtom } from 'jotai';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TagsInput from 'components/TagsInput';
import TagsInputSearch from 'components/TagsInputSearch';
import SelectGroup from "components/SelectGroup";
import { AuthContext } from '../../context/authContext';
import BackToTopButton from 'components/BackToTopButton';
import _ from 'lodash';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Settings = () => {
  const navigate = useNavigate();
  const [emails, setEmails] = useState([])
  const [tags, setTags] = useState([])
  const { isAuthenticated, setPortfolioContext } = useContext(AuthContext);

  const weekdays = ['MON', 'TUES', 'WED', 'THUR', 'FRI', 'SAT', 'SUN']

  if (!isAuthenticated) {
    navigate('/sign-in');
  }

  const [facilityData, setFacilityData] = useAtom(facilityDataAtom);
  const [portfolioData, setPortfolioData] = useAtom(portfolioDataAtom)
  const [frequency, setFrequency] = useState('DAILY');
  const [day, setDay] = useState('MON');
  const [date, setDate] = useState(0);
  const [time, setTime] = useState('00:00');
  const [months, setMonths] = useState(1);
  const [bidLimitMonths, setBidLimitMonths] = useState(0);
  const [dataChanged, setDataChanged] = useState({ type: 'success', open: false })
  const [facilityChanged, setFacilityChanged] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    reloadData()
  }, [])

  const changePortfolioStrategy = (value) => {

    switch (value) {
      case 'TARGET RANKING':
        value = 'target_ranking';
        break;
      case 'FACILITY MAX BID':
        value = 'facility_max_bid';
        break;
      case 'PORTFOLIO MAX BID':
        value = 'portfolio_max_bid';
        break;
      default:
        value = 'portfolio_max_bid';
    }

    setPortfolioData({
      ...portfolioData,
      current_strategy: value
    })
  }

  const changeFacilitySetting = (value, key, _id) => {
    switch (value) {
      case 'ENABLED':
        value = true;
        break;
      case 'DISABLED':
        value = false;
        break
      default:
        value = true;
    }

    facilityData[key].facility.visible = value

    setFacilityData(facilityData);

    const facilityChangedIndex = facilityChanged.findIndex(value => value._id === _id);

    if (facilityChangedIndex !== -1) {
      facilityChanged[facilityChangedIndex].visible = value;
    } else {
      facilityChanged.push({
        _id,
        visible: value
      });
    }

    setFacilityChanged(facilityChanged);
  }

  const reloadData = () => {
    setLoading(true)
    axiosClient.get("/portfolio/user").then(response => {
      const portfolio = response.data.result
      setPortfolioData(portfolio);
      setPortfolioContext(portfolio)
      setMonths(portfolio.clv)
      setBidLimitMonths(portfolio.bid_limit || 0)
      if (!portfolio.notification_emails?.length) {
        const user = JSON.parse(localStorage.getItem('user'))
        setEmails([user.email])
      } else {
        setEmails(portfolio.notification_emails)
      }

      if (portfolio.tags?.length > 0) {
        setTags(portfolio.tags)
      }

      setFrequency(portfolio.frequency || 'DAILY')
      if (portfolio.day == null) portfolio.day = 1
      setDay(weekdays[(portfolio.day + 6) % 7])
      setDate(portfolio.date || 0)
      setTime(portfolio.time || '00:00')
      setLoading(false)
    })

    getFacilityData()
  }

  const getFacilityData = () => {
    setLoading(true)
    axiosClient.get("/facility?all=true").then(result => {
      setFacilityData(result.data.result);
      setLoading(false)
    })
  }

  const saveData = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    axiosClient
      .put(`/portfolio/portfolio_id/${user.portfolio_id}`, {
        clv: months,
        current_strategy: portfolioData.current_strategy,
        bid_limit: bidLimitMonths,
      })
      .then((result) => {
        setPortfolioData({
          ...portfolioData,
          clv: months,
        })
        let { data } = result
        if (data.result.ok === 1) {
          setDataChanged({ type: 'success', open: true })
        } else {
          setDataChanged({ type: 'error', open: true })
        }
      })

    axiosClient.post('portfolio/save_notifcation_settings', {
      frequency: frequency,
      day: (weekdays.indexOf(day) + 1) % 7,
      date,
      time,
      notification_emails: emails
    }).then((response) => {
      const { result } = response.data
      setEmails(result.notification_emails)
    })

    axiosClient.post('facility/update_visible_items', facilityChanged).then((result) => {
      setFacilityChanged([]);
      let { data } = result
      if (data.statusCode === 200) {
        setDataChanged({ type: 'success', open: true })
      } else {
        setDataChanged({ type: 'error', open: true })
      }
    })

    facilityData.forEach(facility => {
      const clv = facility.facility.clv;

      if (clv > 0) {
        axiosClient.put(`facility/${facility.facility._id}`, {
          clv
        }).then((result) => {
          const { data } = result
          if (data.statusCode === 200) {
            setDataChanged({ type: 'success', open: true })
          } else {
            setDataChanged({ type: 'error', open: true })
          }
        })
      }
    })
  }

  const handleNotificationChange = (selectedOption) => {
    setFrequency(selectedOption);
  };

  const handleMonthsChange = (e) => {
    const value = e.target.value;

    const isNumeric = /^[0-9]+$/.test(value);

    if (isNumeric) {
      const numericValue = parseInt(value, 10);

      if (numericValue > 99) {
        setMonths(99);
      } else if (numericValue < 1) {
        setMonths(1);
      } else {
        setMonths(numericValue);
      }
    }
  };

  const handleFacilityMonthsChange = (e, key, _id) => {
    const value = e.target.value;

    const isNumeric = /^[0-9]+$/.test(value);

    if (isNumeric) {
      let numericValue = parseInt(value, 10);

      if (numericValue > 99) {
        numericValue = 99;
      } else if (numericValue < 1) {
        numericValue = 1;
      }

      const updatedFacilityData = [...facilityData];

      // Check if the facility and its tags exist before trying to update
      if (updatedFacilityData[key] && updatedFacilityData[key].facility) {
        updatedFacilityData[key].facility.clv = numericValue;
        setFacilityData(updatedFacilityData);
      }
    }
  }

  const handleClose = () => {
    setDataChanged({ ...dataChanged, open: false })
  }

  const strategyId = () => {
    switch (portfolioData.current_strategy) {
      case 'target_ranking':
        return 'TARGET RANKING';
      case 'facility_max_bid':
        return 'FACILITY MAX BID';
      case 'portfolio_max_bid':
        return 'PORTFOLIO MAX BID';
      default:
        return 'PORTFOLIO MAX BID';
    }
  };

  const defaultFacilitySetting = (value) => {
    switch (value) {
      case true:
        return 'ENABLED';
      case false:
        return 'DISABLED';
      default:
        return 'ENABLED';
    }
  };

  const handleAddTags = (values) => {
    if (values.length > 50) {
      return false;
    }

    values = _.uniq(values);

    axiosClient.post('portfolio/save_tags', {
      tags: values
    }).then((response) => {
      const { result } = response.data
      setTags(result.tags);
    })
  }

  const hanldeChangeTag = (value) => {
    axiosClient.post('facility/sync_tags', value)
      .then((response) => {
        axiosClient.get("/facility?all=true").then(result => {
          getFacilityData()
        })
      })
  }

  const handleRemoveTag = (value) => {
    axiosClient.post('facility/delete_tags', {
      tag: value
    }).then((response) => {
      getFacilityData()
    })
  }

  const handleAddFacilityTags = useCallback((values, key, _id) => {
    values = _.uniq(values);

    const updatedFacilityData = [...facilityData];

    // Check if the facility and its tags exist before trying to update
    if (updatedFacilityData[key] && updatedFacilityData[key].facility) {

      updatedFacilityData[key].facility.tags = values;

      setFacilityData(updatedFacilityData);

      axiosClient.put(`facility/${_id}`, {
        tags: values
      }).then((result) => {
      }).catch((error) => {
        // Optionally, handle any error
        console.error("Error updating facility tags:", error);
        // Consider reverting the state if the update fails
      });
    }
  }, [facilityData, setFacilityData]);

  return (
    <DashboardLayout>
      <Snackbar open={dataChanged.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dataChanged.type} sx={{ width: '100%' }}>
          {dataChanged.type === 'success'
            ? 'Updated Successfully'
            : 'Error acquired! Please Try Again'}
        </Alert>
      </Snackbar>
      <Card>
        <Container sx={{ padding: { xs: 2, md: 4 } }}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '90vh',
                fontSize: 16,
                color: '#555'
              }}
            >
              Loading ...
            </div>
          ) : (
            <Grid container spacing={2} py={{ xs: 2, md: 4 }}>
              <Grid container sx={{ justifyContent: 'flex-end', marginTop: 3 }}>
                <MDButton variant="outlined" color="error" onClick={saveData}>
                  Save
                </MDButton>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ color: '#7b809a', fontWeight: 'bold' }}
                >
                  Bidding
                </Typography>
                <Box
                  mt={2}
                  py={3}
                  px={4}
                  rowGap={2}
                  sx={{
                    border: '1px solid #7b809a',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid item sm={4} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Grid item>
                      <TipsAndUpdatesOutlinedIcon
                        color="error"
                        style={{
                          fontSize: { xs: 30, sm: 40 },
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        Strategy
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Your bidding strategy. You may also update this strategy on the main page." placement="top">
                        <IconButton>
                          <HelpOutlineOutlinedIcon
                            color="error"
                            style={{
                              fontSize: { xs: 30, sm: 40 },
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '2rem' }} sm={8}>
                    <SelectGroup
                      options={['PORTFOLIO MAX BID', 'FACILITY MAX BID', 'TARGET RANKING']}
                      clickHandler={changePortfolioStrategy}
                      selected={strategyId()}
                    />
                  </Grid>
                </Box>
                <Box
                  mt={2}
                  py={3}
                  px={4}
                  sx={{
                    border: '1px solid #7b809a',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                  }}>
                  <Grid item sm={4} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Grid item>
                      <TipsAndUpdatesOutlinedIcon
                        color="error"
                        style={{
                          fontSize: { xs: 30, sm: 40 },
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        Portfolio Bid Limit
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="Your portolio's bid limit. This acts as a guardrail to protect from overbidding." placement="top">
                        <IconButton>
                          <HelpOutlineOutlinedIcon
                            color="error"
                            style={{
                              fontSize: { xs: 30, sm: 40 },
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item sm={8} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <TextField
                      type="number"
                      value={bidLimitMonths}
                      InputProps={{ inputProps: { min: 1, max: 99 } }}
                      style={{ width: '70px' }}
                      onChange={(e) => {
                        const value = e.currentTarget?.value || 0
                        setBidLimitMonths(value)
                      }}
                    />{' '}
                    <Typography variant="h6" component="p" style={{ fontWeight: 'lighter' }}>
                      months
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ color: '#7b809a', fontWeight: 'bold' }}
                >
                  Customer Lifetime Value
                </Typography>
                <Box
                  mt={2}
                  py={3}
                  px={4}
                  sx={{
                    border: '1px solid #7b809a',
                    borderRadius: '10px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} sm={4}>
                    <Grid item>
                      <TipsAndUpdatesOutlinedIcon
                        color="error"
                        style={{
                          fontSize: { xs: 30, sm: 40 },
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        Average CLV in Months
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="This will update the value in the CLV visualization when you update your max bids." placement="top">
                        <IconButton>
                          <HelpOutlineOutlinedIcon
                            color="error"
                            style={{
                              fontSize: { xs: 30, sm: 40 },
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item sm={8} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <TextField
                      type="number"
                      value={months}
                      InputProps={{ inputProps: { min: 1, max: 99 } }}
                      style={{ width: '70px' }}
                      onChange={handleMonthsChange}
                    />{' '}
                    <Typography variant="h6" component="p" style={{ fontWeight: 'lighter' }}>
                      months
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ color: '#7b809a', fontWeight: 'bold' }}
                >
                  Notification
                </Typography>
                <Box
                  mt={2}
                  py={3}
                  px={4}
                  sx={{
                    border: '1px solid #7b809a',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} sm={4}>
                    <Grid item>
                      <GraphicEqOutlinedIcon
                        color="error"
                        style={{
                          fontSize: { xs: 30, sm: 40 },
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        Notification Frequency
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '2rem' }} sm={8}>
                    <SelectGroup
                      options={['DAILY', 'WEEKLY', 'MONTHLY', 'OFF']}
                      selected={frequency}
                      clickHandler={handleNotificationChange}
                    />
                  </Grid>
                </Box>

                {frequency === 'MONTHLY' && (
                  <Box
                    mt={2}
                    py={3}
                    px={4}
                    sx={{
                      border: '1px solid #7b809a',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      flexWrap: 'wrap',
                    }}
                  >
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                      spacing={1}
                      sm={4}
                    >
                      <Grid item>
                        <GraphicEqOutlinedIcon
                          color="error"
                          style={{
                            fontSize: { xs: 30, sm: 40 },
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" component="h6">
                          Notification Day Of Month
                        </Typography>
                      </Grid>
                    </Grid>

                    <FormControl sx={{ m: 1, minWidth: 120 }} sm={8} size="small">
                      <InputLabel id="demo-select-small-label">Day</InputLabel>
                      <Select
                        style={{ height: '40px' }}
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={date}
                        label="Day"
                        onChange={(e) => setDate(e.target.value)}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200, // Set your desired max height here
                            },
                          },
                        }}
                      >
                        <MenuItem value="0">
                          <em>None</em>
                        </MenuItem>
                        {Array.from({ length: 31 }, (_, index) => (
                          <MenuItem key={index} value={index + 1}>
                            {index + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}

                {frequency === 'WEEKLY' && (
                  <Box
                    mt={2}
                    py={3}
                    px={4}
                    sx={{
                      border: '1px solid #7b809a',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: { xs: 'column', sm: 'row' },
                      flexWrap: 'wrap',
                    }}
                  >
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                      sm={4}
                    >
                      <Grid item>
                        <GraphicEqOutlinedIcon
                          color="error"
                          style={{
                            fontSize: { xs: 30, sm: 40 },
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" component="h6">
                          Notification Day Of Week
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}
                      sm={8}
                    >
                      <SelectGroup
                        options={weekdays}
                        clickHandler={(val) => setDay(val)}
                        selected={day}
                      />
                    </Grid>
                  </Box>
                )}

                <Box
                  mt={2}
                  py={3}
                  px={4}
                  sx={{
                    border: '1px solid #7b809a',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} sm={4}>
                    <Grid item>
                      <QueryBuilderOutlinedIcon
                        color="error"
                        style={{
                          fontSize: { xs: 30, sm: 40 },
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        Notification Time Of Day
                      </Typography>
                    </Grid>
                  </Grid>
                  <TextField
                    sm={10}
                    id="time"
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  />
                </Box>
                <Box
                  mt={2}
                  py={3}
                  px={4}
                  sx={{
                    border: '1px solid #7b809a',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} sm={4}>
                    <Grid item>
                      <EmailOutlinedIcon
                        color="error"
                        style={{
                          fontSize: { xs: 30, sm: 40 },
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        Notification Email(s)
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Enter a comma "," between email addresses to send the notification to multiple people, e.g. email1@bigfoot.com,email2@bigfoot.com' placement="top">
                        <IconButton>
                          <HelpOutlineOutlinedIcon
                            color="error"
                            style={{
                              fontSize: { xs: 30, sm: 40 },
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }} sm={8}>
                    <TagsInput values={emails} type={'email'} placeholder={'Hit ENTER to add a new email.'} onChange={(values) => setEmails(values)} />
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ color: '#7b809a', fontWeight: 'bold' }}
                >
                  Tags
                </Typography>
                <Box
                  mt={2}
                  py={3}
                  px={4}
                  sx={{
                    border: '1px solid #7b809a',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    flexWrap: 'wrap',
                  }}
                >
                  <Grid item style={{ display: 'flex', alignItems: 'center', gap: '1rem' }} sm={4}>
                    <Grid item>
                      <SellOutlined
                        color="error"
                        style={{
                          fontSize: { xs: 30, sm: 40 },
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" component="h6">
                        Category Tags
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title='Use tags to help filter your view of your portfolio.' placement="top">
                        <IconButton>
                          <HelpOutlineOutlinedIcon
                            color="error"
                            style={{
                              fontSize: { xs: 30, sm: 40 },
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }} sm={8}>
                    <TagsInput values={tags}
                      type={'text'}
                      placeholder={'Hit ENTER to add a new tag.'}
                      canEdit={true}
                      onChange={(values) => handleAddTags(values)}
                      onChangeItem={(value) => hanldeChangeTag(value)}
                      onDeleteItem={(value) => handleRemoveTag(value)}
                    />
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  component="h6"
                  style={{ color: '#7b809a', fontWeight: 'bold' }}
                >
                  Facilities
                </Typography>
                {facilityData.map((facility, key) => {
                  return (
                    <Box
                      key={key}
                      mt={2}
                      py={3}
                      px={4}
                      sx={{
                        border: '1px solid #7b809a',
                        borderRadius: '10px',
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid
                        item
                        style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                        sm={4}
                      >
                        <Grid item>
                          <Typography variant="h6" component="h6">
                            {facility.facility.facility_name}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}
                        sm={2}
                      >
                        <SelectGroup
                          key={key}
                          options={['ENABLED', 'DISABLED']}
                          clickHandler={(value) =>
                            changeFacilitySetting(value, key, facility?.facility?._id)
                          }
                          selected={facility?.facility?.visible ? 'ENABLED' : 'DISABLED'}
                        />
                      </Grid>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }} sm={4}>
                        <div style={{ marginLeft: 10 }}>
                          <TagsInputSearch key={key} options={tags} values={facility?.facility?.tags || []} type={'text'} placeholder={'Search and hit ENTER to add tags.'} onChange={(values) => handleAddFacilityTags(values, key, facility?.facility?._id)} />
                        </div>
                      </Grid>
                      <Grid item sm={2} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <Typography variant="h6" component="p" style={{ fontWeight: 'bold', marginLeft: '20px' }}>
                          CLV
                        </Typography>
                        <TextField
                          type="number"
                          value={facility?.facility?.clv}
                          InputProps={{ inputProps: { min: 1, max: 99 } }}
                          style={{ width: '70px' }}
                          onChange={(e) => handleFacilityMonthsChange(e, key, facility?.facility?._id)}
                        />
                      </Grid>
                    </Box>
                  )
                })}
              </Grid>
            </Grid>
          )}
        </Container>
        <BackToTopButton />
      </Card>
    </DashboardLayout>
  )
}

export default Settings;
