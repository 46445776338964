/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from 'react';

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { TableCell } from "@mui/material";

const TooltipContentComponent = React.forwardRef(function TooltipContentComponent(props, ref) {
  //  Spread the props to the underlying DOM element.
  return <div {...props} ref={ref}>{ props.children }</div>
});

function DataTableHeadCell({ width, children, sorted, align, column,...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <TableCell
      py={1.5}
      px={3}
      sx={({ palette: { light }, borders: { borderWidth } }) => ({
        borderBottom: `${borderWidth[1]} solid ${light.main}`,
        padding: "12px 2px",
        width: width,
      })}
    >
      <MDBox
        {...rest}
        position="relative"
        display="flex"
        alignItems="center"
        textAlign={align}
        color={darkMode ? "white" : "secondary"}
        opacity={0.7}
        sx={({ typography: { size, fontWeightBold } }) => ({
          fontSize: size.xxs,
          fontWeight: fontWeightBold,
          textTransform: "uppercase",
          cursor: sorted && "pointer",
          userSelect: sorted && "none",
          paddingLeft: 1,
          paddingRight: 1,
        })}
      >
        <Tooltip title={column?.tooltip} placement="top">
          <TooltipContentComponent children={children}/>
        </Tooltip>
        {sorted && (
          <MDBox
            position="relative"
            sx={({ typography: { size } }) => ({
              fontSize: size.lg,
              paddingLeft: 1,
              paddingRight: 1,
            })}
          >
            <MDBox
              position="absolute"
              top={-12}
              color={sorted === "asce" ? "text" : "secondary"}
              opacity={sorted === "asce" ? 1 : 0.5}
            >
              <Icon>arrow_drop_up</Icon>
            </MDBox>
            <MDBox
              position="absolute"
              top={-6}
              color={sorted === "desc" ? "text" : "secondary"}
              opacity={sorted === "desc" ? 1 : 0.5}
            >
              <Icon>arrow_drop_down</Icon>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
    </TableCell>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
