import { useState, memo } from 'react'
import styles from './styles.module.css'

const TagsInputSearch = memo(({ values, onChange, options = [], type = '', placeholder = '' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTags, setFilteredTags] = useState([]);

  function handleKeyDown(e) {
    const value = e.target.value
    if (e.key === 'Enter') {
      if (!value.trim()) return

      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      if (type === 'email' && !regex.test(value)) return

      // console.log(values);
      onChange([...values, value])
      e.target.value = ''
      setSearchTerm(''); // Clear search term
      setFilteredTags([]); // Clear filtered tags
    }
    // if (e.key === 'Backspace' && !value) {
    //   if (values.length < 2) return
    //   values.pop()
    //   onChange([...values])
    // }
  }

  function removeTag(index) {
    onChange(values.filter((el, i) => i !== index))
  }

  function handleSearchChange(e) {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    if (!searchTerm) {
      setFilteredTags([]);
      return;
    }

    // Filter tags based on the search term
    const newFilteredTags = options.filter(tag =>
      tag.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTags(newFilteredTags);
  }

  function addTagFromSelection(tag) {
    if (!tag.trim()) return

    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (type === 'email' && !regex.test(tag)) return

    // console.log(values);
    Array.from(document.getElementsByClassName("tags-input-selection")).forEach(el => el.value = '');

    onChange([...values, tag])
    setSearchTerm(''); // Clear search term
    setFilteredTags([]); // Clear filtered tags
  }

  return (
    <div className={styles['tags-input-container']}>
      {values.length > 0 && values.map((tag, index) => (
        <div className={styles['tag-item']} key={index}>
          <span className="text">{tag}</span>
          <span className={styles.close} onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <input
        onKeyDown={handleKeyDown}
        onChange={handleSearchChange}
        type="text"
        className={`${styles['tags-input']} tags-input-selection`}
        placeholder={placeholder}
      />
      {filteredTags.length > 0 && (
        <div className={styles['selection-box']}>
          {filteredTags.map((tag, index) => (
            <div key={index} className={styles['selection-item']} onClick={() => addTagFromSelection(tag)}>
              {tag}
            </div>
          ))}
        </div>
      )}
    </div>
  )
});

export default TagsInputSearch
