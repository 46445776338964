import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'


export const expandedRowAtom = atom(null);

export const changedMaxBidCountAtom = atomWithStorage(0);

export const facilityDataAtom = atom([]);
export const portfolioDataAtom = atom([]);
export const userDataAtom = atom([]);
export const readySubmitAtom = atom(false)

export const changedMaxBidsAtom = atom([]);
