import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Typography from "@mui/material/Typography";
import MDButton from 'components/MDButton';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';


const PasswordResetModal = ({ modalOpen, onClose }) => {
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  const passwordReset = () => {
    if (!email) return;
    axios.post(`/forgot-password`, { email: email }).then((result) => {
      let { data } = result
      console.log(data)
      if (data.statusCode == 200) {
        setSent(true)
        setError(false)
      } else {
        setSent(false);
        setError(true)
      }
    }).catch((error) => {
      setError(true)
    })
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={modalOpen}>
      <form onSubmit={(e) => {
        e.preventDefault();
        passwordReset()
      }}>
        <FormControl fullWidth>
          <DialogTitle textAlign="center">
            Forgot Password?</DialogTitle>
          <DialogContent style={{ padding: '20px 30px 20px 30px' }}>
            <DialogContentText>
              Please enter your email address.
            </DialogContentText>
            <div style={{ textAlign: "center" }}>
              <TextField style={{ margin: '20px 0' }} fullWidth label="Email" placeholder="Email" error={error} value={email} onChange={(e) => setEmail(e.target.value)} required />
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: "space-between" }}>
            <MDButton variant="outlined" color="error" onClick={handleClose} >Cancel</MDButton>
            <MDButton variant="outlined" color="error" type="submit">Send</MDButton>
          </DialogActions>
        </FormControl>
      </form>
      {error && <div style={{ maxWidth: 250, padding: 10, margin: 'auto' }}><Typography variant="h6" component="h6" align="center" color="error">Email not found. Please check your email and try again.</Typography></div>}
      {sent && <div style={{ maxWidth: 250, padding: 10, margin: 'auto' }}><Typography variant="h6" component="h6" align="center" color="success.main">Please check your email for your password reset instructions.</Typography></div>}
    </Dialog>
  );
};

export default PasswordResetModal;