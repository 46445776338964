import { useState, useEffect, useMemo, useContext } from "react";
import { Provider, ErrorBoundary } from '@rollbar/react'

import { useSelector } from "react-redux";

import AuthModal from "components/Modals/authModal";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "containers/Sidenav";


// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandWhite from "assets/images/logo.png";
import brandDark from "assets/images/logo.png";

import { AuthContext } from './context/authContext';

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENVIRONMENT,
}

export default function App() {
  const { auth, sparefoot } = useContext(AuthContext);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [appRoutes, setAppRoutes] = useState(routes)
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();


  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    if (auth?.role === 'global_admin') {
      const newRoutes = [...routes]
      const facilitiesRoute = newRoutes.splice(2, 1)[0]
      newRoutes.splice(newRoutes.length - 2, 0, facilitiesRoute)
      setAppRoutes([...newRoutes])
    } else {
      const newRoutes = routes.filter((rt) => rt.key !== 'portfolios')
      setAppRoutes([...newRoutes])
    }
  }, [auth])

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              <>
                {route.component}
                <AuthModal
                  modalOpen={!sparefoot && auth?.role !== 'global_admin' && route.private}
                />
              </>
            }
            key={route.key}
          />
        )
      }

      return null;
    });

  const defaultRoute = () => {
    if (!auth?.role) return '/sign-in'
    if (auth?.role === 'global_admin') return '/portfolios'
    else return '/dashboard'
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <Provider config={rollbarConfig}>
        {process.env.REACT_APP_ROLLBAR_ENVIRONMENT === 'local' ? (
          <>
            <CssBaseline />
            {!['/sign-in', '/loading', '/reset-password'].includes(pathname) && (
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                routes={appRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            )}
            <Routes>
              {getRoutes(appRoutes)}
              <Route path="*" element={<Navigate to={defaultRoute()} />} />
            </Routes>
          </>
        ) : (
          <ErrorBoundary>
            <CssBaseline />
            {!['/sign-in', '/loading', '/reset-password'].includes(pathname) && (
              <Sidenav
                color={sidenavColor}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName=""
                routes={appRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            )}
            <Routes>
              {getRoutes(appRoutes)}
              <Route path="*" element={<Navigate to={defaultRoute()} />} />
            </Routes>
          </ErrorBoundary>
        )}
      </Provider>
    </ThemeProvider>
  )
}
