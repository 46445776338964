import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import MDButton from "components/MDButton";
import axiosClient from '../../helpers/hooks/axiosClient';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AuthContext } from '../../context/authContext';
import SelectGroup from "components/SelectGroup";
import PortfolioEdit from 'components/PortfolioEdit';

const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Portfolios = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    navigate('/sign-in');
  }

  const [users, setUsers] = useState([])
  const [portfolioData, setPortfolioData] = useState([])
  const [dataChanged, setDataChanged] = useState({ type: 'success', open: false })
  const [portfolioChanged, setPortfolioChanged] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchData()
  }, [])

  const changePortfolioSettings = (value, key, _id) => {
    switch (value) {
      case 'ENABLED':
        value = true;
        break;
      case 'DISABLED':
        value = false;
        break
      default:
        value = true;
    }

    portfolioData[key].enabled = value

    setPortfolioData([...portfolioData]);

    const portfolioChangedIndex = portfolioChanged.findIndex(value => value._id === _id);

    if (portfolioChangedIndex !== -1) {
      portfolioChanged[portfolioChangedIndex].enabled = value;
    } else {
      portfolioChanged.push({
        _id,
        enabled: value
      });
    }

    setPortfolioChanged(portfolioChanged);
  }

  const fetchData = () => {
    setLoading(true)
    axiosClient.get("/portfolio").then(response => {
      const portfolio = response.data.result
      setPortfolioData(portfolio);
      setLoading(false)
    })
    axiosClient.get("/users").then(response => {
      const users = response.data.result?.data
      setUsers(users)
    })
  }

  const saveData = async (portfolioData) => {
    const payload = {...portfolioData}
    const { users } = payload
    delete payload.users

    try {
      let response = await axiosClient.put(`/portfolio/${portfolioData._id}`, payload)
      
      let statusOK = response.data.statusCode === 200
      const { result: portfolio } = response.data

      response = await axiosClient.post('/users/sync', {
        users,
      })
      statusOK &= response.data.statusCode === 200

      if (statusOK) {
        setDataChanged({ type: 'success', open: true })
      } else {
        setDataChanged({ type: 'error', open: true })
      }
      return portfolio
    } catch (e) {
      setDataChanged({ type: 'error', open: true })
    }
  }

  const handleClose = () => {
    setDataChanged({ ...dataChanged, open: false })
  }

  const defaultFacilitySetting = (value) => {
    switch (value) {
      case true:
        return 'ENABLED';
      case false:
        return 'DISABLED';
      default:
        return 'ENABLED';
    }
  };

  const openPortfolioSettings = (index) => {
    portfolioData[index].openSettings = !portfolioData[index].openSettings
    setPortfolioData([...portfolioData])
  }

  const handleClosePortfolioSettings = async(index, data) => {
    portfolioData[index].saving = true
    setPortfolioData([...portfolioData])
    
    portfolioData[index] = await saveData(data)
    portfolioData[index].openSettings = false
    portfolioData[index].saving = false
    setPortfolioData([...portfolioData])
  }

  const addPortfolio = () => {
    let maxId = portfolioData?.reduce(
      (max, port) => (port.portfolio_id > max ? port.portfolio_id : max),
      0
    )
    portfolioData.push({
      portfolio_id: maxId + 1,
      openSettings: true
    })
    setPortfolioData([...portfolioData])
  }

  return (
    <DashboardLayout>
      <Snackbar open={dataChanged.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dataChanged.type} sx={{ width: '100%' }}>
          {dataChanged.type === 'success'
            ? 'Updated Successfully'
            : 'Error acquired! Please Try Again'}
        </Alert>
      </Snackbar>
      <Card>
        <Container sx={{ padding: { xs: 2, md: 4 }, minHeight: '95vh' }}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '90vh',
                fontSize: 16,
                color: '#555'
              }}
            >
              Loading ...
            </div>
          ) : (
            <Grid container spacing={2} py={{ xs: 2, md: 4 }}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      component="h6"
                      style={{ color: '#7b809a', fontWeight: 'bold' }}
                    >
                      Portfolios
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <MDButton sx={{ ml: 1 }} variant="outlined" color="error" onClick={addPortfolio}>
                      Add Portfolio
                    </MDButton>
                  </Grid>
                </Grid>
                {portfolioData?.map((portfolio, key) => {
                  return (
                    <Box
                      key={key}
                      mt={2}
                      py={3}
                      px={4}
                      sx={{
                        border: '1px solid #7b809a',
                        borderRadius: '10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: { xs: 'column', sm: 'row' },
                          flexWrap: 'wrap',
                        }}
                      >
                        <Grid
                          item
                          style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                          sm={4}
                        >
                          <Grid item>
                            <Typography variant="h6" component="h6">
                              {portfolio.portfolio_name}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }} sm={8}>
                          <SelectGroup
                            options={['ENABLED', 'DISABLED']}
                            clickHandler={(value) =>
                              changePortfolioSettings(value, key, portfolio._id)
                            }
                            selected={defaultFacilitySetting(portfolio.enabled)}
                          />
                          <MDButton
                            sx={{
                              width: { xs: '100%', sm: 'auto' },
                              marginBottom: { xs: 1, sm: 0 },
                              ml: 1,
                            }}
                            variant="outlined"
                            color="dark"
                            onClick={() => openPortfolioSettings(key)}
                          >
                            SETTINGS
                          </MDButton>
                        </Grid>
                      </Box>
                      {portfolio.openSettings && <PortfolioEdit data={portfolio} onSave={(data) => handleClosePortfolioSettings(key, data)}/>}
                    </Box>
                  )
                })}
              </Grid>
            </Grid>
          )}
        </Container>
      </Card>
    </DashboardLayout>
  )
}

export default Portfolios;