import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axiosClient from '../../helpers/hooks/axiosClient';
import Typography from "@mui/material/Typography";
import MDButton from 'components/MDButton';
import { userDataAtom } from 'store/data';
import { useAtom } from 'jotai';



const UserDeletionModal = ({ modalOpen, id, onClose }) => {
  const [error, setError] = useState(false)
  const [users, setUsers] = useAtom(userDataAtom);

  const deleteUser = () => {
    axiosClient.delete(`/users/${id}`).then((result) => {
      let { data } = result
      if (data.statusCode == 200) {
        setUsers(users.filter((usr) => usr._id != id))
        handleClose()
      } else {
        setError(true)
      }
    }).catch((error) => {
      setError(true)
    })
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={modalOpen} onBackdropClick={handleClose}>
      <DialogTitle textAlign="center">
        User Deletion</DialogTitle>
      <DialogContent style={{ padding: '20px 30px 20px 30px' }}>
        <DialogContentText>
          Are you sure you want to delete this user?
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <MDButton variant="outlined" color="error" onClick={handleClose} >Cancel</MDButton>
        <MDButton variant="outlined" color="error" onClick={deleteUser}>Yes</MDButton>
      </DialogActions>
      {error && <Typography variant="h6" component="h6" align="center" color="error" style={{ margin: '20px 0' }}>Failed to delete this user.</Typography>}
    </Dialog>
  );
};

export default UserDeletionModal;
