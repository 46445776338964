import React, { useState,useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, Checkbox } from "@mui/material"
import MDBox from "components/MDBox"
import brandWhite from "../../assets/images/logo.png";
import MDButton from "components/MDButton";
import { useLocation } from "react-router-dom";
import axios from 'axios';
import PasswordStrengthBar from 'react-password-strength-bar';
import { AuthContext } from '../../context/authContext';

const termOfServiceURL = 'https://spareboxtech.com/terms-of-service-bigfoot-prophet/';

const ResetPassword = () => {
  const myParam = useLocation().search;
  const emailParam = new URLSearchParams(myParam).get("email");
  const token = new URLSearchParams(myParam).get("token");

  const navigate = useNavigate();
  const [email, setEmail] = useState(emailParam);
  const { login } = useContext(AuthContext);
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [resspasswordError, setResspasswordError] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [errMessage, setErrMessage] = useState('')
  const [eula, setEULA] = useState(false);

  const handleResetPassword = () => {
    const form = {
      token,
      email,
      password,
      confirmedPassword,
      eula
    }

    axios.post('/reset-password', form).then((result) => {
      navigate('/sign-in');
    }).catch((error) => {
      setErrMessage(error.response.data.error)
      setResspasswordError(true);
    })
  };

  const handleConfirmedPassword = (e) => {
    const value = e.target.value;
    setConfirmedPassword(value);
    setPasswordMatch(value === password);
  }

  const onChangeScore = (score, feedback) => {
    setSubmitDisabled(!(score >= 2))
  }

  return (
    <Grid container style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Grid container xs={12} sm={6} style={{ borderRadius: '20px' }}>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f9cdc7',
            padding: '80px 0 ',
            borderRadius: '20px 0 0 20px'
          }}
        >
          <Grid item style={{ width: '80%', maxWidth: '400px', padding: '0 20px' }}>
            <Typography color="error" variant="h3">
              Welcome!
            </Typography>
            <Typography color="error" variant="h3">
              Set Your Password
            </Typography>
            <Grid item pt={5}>
              <input
                type="text"
                id="email"
                placeholder="Email"
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ width: '100%', outline: 'none', border: 'none', background: 'white', padding: '12px 25px', borderRadius: '999px', marginBottom: '15px', color: '#555' }}
              />
              <input
                variant="standard"
                type="password"
                id="password"
                placeholder="Password"
                required
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ width: '100%', outline: 'none', border: 'none', background: 'white', padding: '12px 25px', borderRadius: '999px', marginBottom: '15px', color: '#555' }}
              />

              {password && <Typography style={{ fontSize: 12, color: 'rgb(85, 85, 85)' }}>To conform with our Strong Password policy, you are required to use a sufficiently strong password.</Typography>}
              {password && <PasswordStrengthBar className="passwordStrengthBar" password={password} style={{ paddingBottom: 10 }} barColors={['#3d658f', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']} onChangeScore={(score, feedback) => onChangeScore(score, feedback)} />}
              <input
                variant="standard"
                type="password"
                id="confirmed-password"
                placeholder="Confirm Password"
                required
                fullWidth
                value={confirmedPassword}
                onChange={(e) => handleConfirmedPassword(e)}
                style={{ width: '100%', outline: 'none', border: 'none', background: 'white', padding: '12px 25px', borderRadius: '999px', marginBottom: '15px', color: '#555' }}
              />
              {(confirmedPassword && !passwordMatch) && <Typography pb={2} color="error" size="small" style={{fontSize: 12, color: 'red'}}>Confirm password does not match</Typography>}
              <MDBox display="flex" alignItems="center">
                <Checkbox checked={eula} onChange={(e) => setEULA(e.target.checked)} style={{ backgroundColor: 'white', padding: 0, marginRight: 10 }} />
                <span style={{ fontSize: 12 }} onClick={()=>setEULA(!eula)} >I have read and agree to the 
                  <a 
                    target="_blank"
                    rel="noreferrer"
                   href={termOfServiceURL} style={{ fontSize: 12, textDecoration: 'underline', color: 'blue' }}> Terms of Service</a>
                </span>
              </MDBox>
            </Grid>
            {(resspasswordError) && <Typography pb={2} color="error" style={{ fontSize: 16 }}>{errMessage}</Typography>}
            <MDButton disabled={submitDisabled || !passwordMatch || !eula} variant="contained" color="error" style={{ marginTop: 30 }} fullWidth onClick={handleResetPassword}>
              Submit
            </MDButton>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'white',
            borderRadius: '0px 20px 20px 0px'
          }}
        >
          <MDBox component="img" src={brandWhite} alt="Brand" width="80%" maxWidth="300px" />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ResetPassword