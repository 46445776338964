// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function DataTableBodyCell({ noBorder, align, children, column, row }) {
  return (
    <MDBox
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
      })}
    >
      <MDBox
        display="inline-block"
        style={{ maxWidth: "13rem", whiteSpace: 'wrap' }}

        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {
          column.id === 'o_bid' ? 
          (
            <MDTypography component="a" href="#" color="text">
              <MDBox bgColor={row.has_new_bid ? "#c7edc7" : "#FFFF8F"} align="center" padding="4px" borderRadius="8px" width="80px">
                <MDTypography fontSize="small">{children}</MDTypography>
              </MDBox>
            </MDTypography>
          ) : 
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {children}
        </MDTypography>
        }
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
