import axios from 'axios';
import { useState, useEffect } from 'react';
import { useRollbar } from '@rollbar/react';
import { getStorageToken } from 'helpers/storage';

axios.defaults.baseURL = '/api/v1';

axios.interceptors.request.use((config) => {
    const token = getStorageToken()
    
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

const useHttp = ({ url, method, body = null, headers = null, autoRun = true }) => {
    const rollbar = useRollbar();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState("");
    const [loading, setloading] = useState(true);
    const [called, setCalled] = useState(false);

    const fetchData = async () => {
        axios[method](url, JSON.parse(body), JSON.parse(headers))
            .then((res) => {
                setResponse(res.data);
            })
            .catch((err) => {
                setError(err);
                rollbar.error("api fetch error", err);
            })
            .finally(() => {
                setloading(false);
                setCalled(true)
            });
    };

    useEffect(() => {
        if (!autoRun) {
            return;
        }
        fetchData();
    }, [method, url, body, headers]);

    return { response, error, loading, setloading, fetchData, called };
};

export default useHttp;
