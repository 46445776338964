import React, { useState, useEffect } from 'react';
import { ReactComponent as ArrowCircleUpOutlined } from '../../assets/images/icons/ArrowCircleUpOutlined.svg';
import styles from './styles.module.css'

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div className="back-to-top">
      {isVisible &&
        <div onClick={scrollToTop} className={styles.button}>
          <ArrowCircleUpOutlined width={45}/>
        </div>
      }
    </div>
  );
};

export default BackToTopButton;