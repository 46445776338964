import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from '../../context/authContext';
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import { Container } from '@mui/material';

// Data

const InsightScreen = () => {
  const { portfolio } = useContext(AuthContext);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const portfolioId = portfolio?.portfolio_id;

  const appEnv = process.env.REACT_APP_ENV || "staging";

  const EMBED_URL_ENDPOINT = `https://6o69nts7s4.execute-api.us-east-1.amazonaws.com/default/quicksight-embed-url-for-bigfoot?app_env=${appEnv}`;

  useEffect(() => {
    // useEffect hook to fetch data when the component mounts
    const timeout = setTimeout(() => {
      fetch(
        [EMBED_URL_ENDPOINT] // Replace [YOUR_API_ENDPOINT] with the actual API endpoint URL
      )
        .then(
          (response) => response.json() // Parsing the response to JSON
        )
        .then((response) => {
          // setDashboardUrl(response.EmbedUrl)
          setDashboardUrl(response.EmbedUrl + "#p.portfolioid=" + portfolioId); // Setting the dashboard URL state with the embed URL from the response
        });
    }, 10); // Fetching the data after a timeout of 10 milliseconds
    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, []);

  return (
    <DashboardLayout>
      <Card>
        <div className="quicksight-embeded-iframe">
          <iframe width="100%" height="720" src={dashboardUrl}>
            {/* Embedding the QuickSight dashboard using the iframe */}
          </iframe>
        </div>
      </Card>
    </DashboardLayout>
  );
}

export default InsightScreen;
