import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import { Container } from '@mui/material';
import MDButton from "components/MDButton";
import { TextField } from '@mui/material';
import axiosClient from '../../helpers/hooks/axiosClient';
import { userDataAtom } from 'store/data';
import { useAtom } from 'jotai';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import _ from 'lodash';
import { AuthContext } from '../../context/authContext';
import { ReactComponent as Trash } from '../../assets/images/small-logos/trash.svg';
import UserDeletionModal from 'components/Modals/userDeletionModal';


const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Users = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  if (!isAuthenticated) {
    navigate('/sign-in');
  }

  const [users, setUsers] = useAtom(userDataAtom);
  const [dataChanged, setDataChanged] = useState({ type: 'success', open: false })
  const [modal, setModal] = useState({ open: false, id: 0 })
  const [id, setId] = useState(0)

  useEffect(() => {
    reloadData()
  }, [])

  const reloadData = () => {
    if (users.length === 0) {
      axiosClient.get("/users?limit=0").then(result => {
        setUsers(result.data.result);
      }).catch((error) => {
        setDataChanged({ type: 'error', open: true })
      })
    }
  }

  const saveData = () => {
    const data = users.filter((user) => user.fullName && user.email)
    axiosClient.post('/users/sync', {
      users: data
    }).then((result) => {
      let { data } = result
      if (data.statusCode === 200) {
        setDataChanged({ type: 'success', open: true })
      } else {
        setDataChanged({ type: 'error', open: true })
      }
    }).catch((error) => {
      setDataChanged({ type: 'error', open: true })
    })
  }

  const userDelete = (user) => {
    if (user.createdAt) {
      setModal({ open: true, id: user._id });
    } else {
      const updatedUsers = users.filter((usr) => usr._id !== user._id);
      setUsers(updatedUsers);
    }
  }

  const createNewUser = () => {
    setUsers([...users, {
      _id: id,
      role: 'user',
      fullName: '',
      email: ''
    }]);
    setId(id + 1)
  }

  const closeModal = () => {
    setModal({ open: false, id: 0 })
  }

  const handleClose = () => {
    setDataChanged({ ...dataChanged, open: false })
  }

  const handleFullNameChange = (e, id) => {
    const value = e.target.value;

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user._id === id ? { ...user, fullName: value } : user
      )
    );
  }

  const handleEmailChange = (e, id) => {
    const value = e.target.value;

    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user._id === id ? { ...user, email: value } : user
      )
    );
  }

  return (
    <DashboardLayout>
      <UserDeletionModal modalOpen={modal.open} id={modal.id} onClose={closeModal} />
      <Snackbar open={dataChanged.open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={dataChanged.type} sx={{ width: '100%' }}>
          {dataChanged.type === 'success' ? 'Updated Successfully' : 'Error acquired! Please Try Again'}
        </Alert>
      </Snackbar>
      <Card>
        <Container sx={{ padding: { xs: 2, md: 4 } }}>
          <Grid container spacing={2} py={{ xs: 2, md: 4 }}>
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" style={{ color: "#7b809a", fontWeight: 'bold' }}>
                User Management
              </Typography>
              {
                users.map((user) => {
                  return (
                    <Box
                      key={user._id}
                      mt={2}
                      py={3}
                      px={4}
                      sx={{
                        border: '1px solid #7b809a',
                        borderRadius: '10px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: { xs: 'column', sm: 'row' },
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid container spacing={1} sm={4}  >
                        <Grid item >
                          <AccountCircleOutlined color="error" style={{ marginTop: 6, fontSize: { xs: 30, sm: 40 }, display: 'flex', alignItems: 'center' }} />
                        </Grid>
                        <Grid item >
                          {_.startCase(_.toLower(user?.role))}
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} sm={4} >
                        <TextField required id="fullname" type="text" placeholder='Full name' defaultValue={user?.fullName} style={{ width: '80%', marginTop: 15 }} onChange={(e) => handleFullNameChange(e, user._id)} />
                      </Grid>
                      <Grid container spacing={2} sm={4} >
                        <TextField required id="email" type="email" placeholder='Email' defaultValue={user?.email} style={{ width: '80%', marginTop: 15 }} onChange={(e) => handleEmailChange(e, user._id)} />
                      </Grid>
                      {
                        user?.role !== 'administrator' &&
                        <Grid item >
                          <Trash fill='red' onClick={() => userDelete(user)} />
                        </Grid>
                      }
                    </Box>
                  )
                })
              }
            </Grid>
            <Grid container sx={{ justifyContent: 'flex-end', paddingTop: '10px' }}>
              <MDButton sx={{ ml: 1 }} color="error" onClick={() => createNewUser()}>Add new user</MDButton>
              <MDButton sx={{ ml: 1 }} variant="outlined" color="error" onClick={() => saveData()}>Save</MDButton>
            </Grid>
          </Grid>
        </Container>

      </Card>
    </DashboardLayout>
  )
}

export default Users;