
export const findNewRanking = (optBid, bidData) => {
  if (!optBid || !bidData) return "";

  const minRank = Object.keys(bidData).reduce((minRank, key) => {
    if (key.startsWith("bid_for_") && bidData[key] && optBid >= bidData[key]) {
      const currentRank = parseInt(key.replace("bid_for_", ""));
      return currentRank < minRank ? currentRank : minRank;
    }
    return minRank;
  }, Infinity);

  return minRank === Infinity ? "" : minRank;
}

export const findNextRankBid = (bidData) => {
  if (!bidData || bidData.rank <= 1) return '';

  for(var i = bidData.rank -1; i >= 0 ; i-- ) {
    const rankKey = 'bid_for_' + i;

    if (bidData[rankKey] !== null) {
      return bidData[rankKey]
    }
  }

  return 0
}