import React, { useMemo, useEffect, useState, useCallback, useContext } from "react";
import { trimStart } from 'lodash'

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SellOutlined from '@mui/icons-material/SellOutlined';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { Checkbox, FormGroup, FormControlLabel, DialogContent } from '@mui/material';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

// Material Dashboard 2 React example components
import DataTableHeadCell from "containers/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "containers/Tables/DataTable/DataTableBodyCell";
import DataTableBodyCollapse from "containers/Tables/DataTable/DataTableBodyCollapse";
import MDButton from "components/MDButton";
import useHttp from "helpers/hooks/useHttp";
import { useRollbar } from '@rollbar/react';

import { useAtom, useSetAtom } from 'jotai'
import { changedMaxBidCountAtom, readySubmitAtom, facilityDataAtom, changedMaxBidsAtom } from "store/data";
import axiosClient from "../../../helpers/hooks/axiosClient";
import { AuthContext } from '../../../context/authContext';
import { findNewRanking } from 'helpers/utils';
import { Grid, Paper, TableHead, DialogContentText } from "@mui/material";
import _ from "lodash";
function DataTable({
  isTargetRanking,
  entriesPerPage,
  onEntriesChange,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  isStickyHeader,
  tags,
  emitTagFilter,
  tagFilterDefault,
}) {
  const { auth, portfolio } = useContext(AuthContext);
  const bidLimit = portfolio?.bid_limit || 0

  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const initialState = table.initialState;
  const countOBidAboveLimit = useMemo(() => data?.filter(bid=> Number(bid?.o_bid || 0) > bidLimit)?.length , [data, bidLimit])

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, ...initialState }, autoResetPage: false, autoResetSortBy: false, autoResetGlobalFilter: false },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const rollbar = useRollbar();

  const [facilityData, setFacilityData] = useAtom(facilityDataAtom);
  const [changedMaxBidCount, setChangedMaxBidCount] = useAtom(changedMaxBidCountAtom)
  const [countBidAboveLimit, setCountBidAboveLimit] = useState(0)
  const [processingPython, setProcessingPython] = useState(false);
  const [readySubmit, setReadySubmit] = useAtom(readySubmitAtom);
  const [submittingBids, setSubmittingBids] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [tagFilter, setTagFilter] = useState(tagFilterDefault);
  const [isModalTagOpen, setIsModalTagOpen] = useState(false);
  const [changedMaxBids, setChangedMaxBids] = useAtom(changedMaxBidsAtom);

  const { fetchData, response: pythonResponse } = useHttp({
    autoRun: false,
    method: 'post',
    url: '/sparefoot/run_python',
    headers: JSON.stringify({ accept: '*/*' }),
  })

  const reloadFacilityData = () => {
    axiosClient.get(`/facility?filter_tags=${JSON.stringify(tagFilter)}`).then(result => {
      setFacilityData(result.data.result);
    })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  const perChunk = 10 // items per chunk    

  const chuckTags = tags.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, []);

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);
  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    if (String(value).startsWith('0')) {
      const newValue = trimStart(String(value), '0')
      setGlobalFilter(newValue)
    } else {
      setGlobalFilter(value || undefined);
    }
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = useCallback((column) => {
    let sortedValue;

    if (isSorted && column.sort && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted && column.sort) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  }, [isSorted]);

  const handleProcessNewMaxBids = () => {
    setProcessingPython(true);
    fetchData();
  }

  const handleSubmitNewBids = () => {
    let newBids = [];
    setSubmittingBids(true);
    for (const item of facilityData) {
      // submit all bids
      const bid = {}
      bid.portfolio_id = item.facility.portfolio_id;
      bid.facility_id = item.facility.facility_id;
      bid.existing_bid = item.city_bid_opportunity?.bid || item.zip_bid_opportunity?.bid;
      bid.max_bid = item.facility.max_bid;
      bid.new_bid = item.new_bid?.bid;
      bid.o_bid = bid.new_bid || bid.max_bid;
      bid.current_strategy = portfolio.current_strategy;
      bid.old_city_rank = item.city_bid_opportunity?.rank;
      bid.old_zip_rank = item.zip_bid_opportunity?.rank;
      bid.new_city_rank = findNewRanking(item.new_bid?.bid, item.city_bid_opportunity)
      bid.new_zip_rank = findNewRanking(item.new_bid?.bid, item.zip_bid_opportunity)
      newBids.push(bid);
    }

    axiosClient.post("/sparefoot/submit", newBids).then(() => {
      alert("Your optimized bids are successfully submitted to SpareFoot. Please wait 20 minutes for changes to reflect.")
      setSubmittingBids(false);
      localStorage.removeItem('bids')
    }).catch((e) => {
      console.error(e, e.message)
      rollbar.error("SpareFoot user doesn’t have admin rights. Please update the user in SpareFoot.");
    })
  }

  useEffect(() => {
    if (pythonResponse) {
      setProcessingPython(false);
      setReadySubmit(true);
      alert("Done Processing New Max Bids");
      reloadFacilityData()
    }
  }, [pythonResponse])

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const handleSubmit = () => {
    setModalOpen(false);
    handleSubmitNewBids();
  };

  function coundMaxBidAboveLimit() {
    const listBids = data?.map(bid=> bid?.max_bid || 0)
    const countAbove = listBids.filter(i=> i > bidLimit).length
    setCountBidAboveLimit(countAbove)
  }


  // console.log("changedMaxBidCount=", changedMaxBidCount, ", readySubmit", readySubmit, "processingPython", processingPython);


  const handleModalTagOpen = () => setIsModalTagOpen(true);
  const handleModalTagClose = () => setIsModalTagOpen(false);

  const handleApply = () => {
    setIsModalTagOpen(false);
    reloadFacilityData();
    emitTagFilter(tagFilter);
    gotoPage(0);
  };

  const handleModalTagCancel = () => {
    setTagFilter([]);
    emitTagFilter([]);
    setIsModalTagOpen(false);
  };

  const handleCheckboxChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setTagFilter([...tagFilter, value]);
    } else {
      setTagFilter(tagFilter.filter(tag => tag !== value));
    }
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ boxShadow: "none", minHeight: 500, height: 'calc(100vh - 400px)' }}>
        <Table {...getTableProps()} stickyHeader={isStickyHeader} aria-label="sticky table">
        <TableHead sx={isStickyHeader ? {position: "sticky", zIndex: 10, top: 0} : {}}>
            <TableRow>
              <TableCell>
                <div style={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
                  {canSearch && (
                      <MDBox width="12rem" >
                        <MDInput
                            placeholder="Search..."
                            value={search}
                            size="small"
                            fullWidth
                            onChange={({ currentTarget }) => {
                              setSearch(search);
                              onSearchChange(currentTarget.value);
                            }}
                        />
                      </MDBox>
                  )}
                  {tags.length > 0 && <Tooltip title='‌Filter by Tags' placement="top">
                    <Badge badgeContent={tagFilter.length} color="error">
                      <IconButton
                          aria-label="sell"
                          style={{
                            borderRadius: '50%', // Makes the button circular
                            padding: 6, // Adjust padding to control the size
                            color: 'white', // Icon color
                            backgroundColor: '#fb8c00', // Button background color
                            '&:hover': {
                              backgroundColor: '#fb8c00', // Button hover background color
                            },
                          }}
                          onClick={handleModalTagOpen}
                      >
                        <SellOutlined />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                  }
                  <Dialog open={isModalTagOpen} onClose={handleModalTagClose} maxWidth="lg">
                    <DialogTitle textAlign="center" style={{ marginLeft: 40, marginRight: 40 }}>
                      Filter by Tags
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <div style={{
                          display: 'grid',
                          gridTemplateColumns: `repeat(${chuckTags.length}, 1fr)`,
                          gridGap: '10px',
                        }}>
                          {chuckTags.map((itemTags, index) => (
                            <div key={index} style={{
                              display: 'flex',
                              flexDirection: 'column',
                              minWidth: '150px',
                            }}>
                              {itemTags.map(tag => {
                                return <FormGroup>
                                  <FormControlLabel control={<Checkbox checked={tagFilter.includes(tag)} value={tag} onChange={(e) => handleCheckboxChange(e)} />} label={tag} />
                                </FormGroup>
                              })}
                            </div>
                          ))} </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                      <MDButton variant="outlined" color="error" onClick={handleApply}>
                        APPLY
                      </MDButton>
                      <MDButton
                          variant="contained"
                          style={{ color: 'white', background: 'grey' }}
                          onClick={handleModalTagCancel}
                      >
                        CANCEL
                      </MDButton>
                    </DialogActions>
                  </Dialog>
                </div>
              </TableCell>
              <TableCell align="left" colSpan={5}>
              <MDBox
                component="div"
                align="center"
                bgColor="red"
                color="white"
                display="block"
                width="100%"
                borderRadius="20px"
              >
                <MDTypography variant="span" color="white" fontWeight="bold">
                    Current
                  </MDTypography>
                </MDBox>
              </TableCell>
              <TableCell align="left">
                <MDBox
                  component="div"
                  align="center"
                  bgColor="black"
                  color="white"
                  display="block"
                  width="100%"
                  borderRadius="20px"
                >
                  <MDTypography variant="span" color="white" fontWeight="bold">
                    New
                  </MDTypography>
                </MDBox>
              </TableCell>
              <TableCell />
              <TableCell align="left" colSpan={2}>
                <MDBox
                  component="div"
                  align="center"
                  bgColor="black"
                  color="white"
                  display="block"
                  width="100%"
                  borderRadius="20px"
                >
                  <MDTypography variant="span" color="white" fontWeight="bold">
                    New
                  </MDTypography>
                </MDBox>
              </TableCell>
              <TableCell align="left" width={300}>
                {countOBidAboveLimit ? <MDTypography fontSize={12} variant="button" color="error" fontWeight="medium">{`${countOBidAboveLimit} Bids above Portfolio Limit`}</MDTypography>:null}
                {auth.role !== "global_admin" && (
                  <div>
                    {changedMaxBidCount > 0 && !readySubmit ? (
                      <MDButton color="success" onClick={handleProcessNewMaxBids}>
                        {processingPython ? "Processing..." : "Process New Max Bids"}
                      </MDButton>
                    ) : (
                      <MDButton color="success" onClick={() => {
                        setModalOpen(true);
                        coundMaxBidAboveLimit();
                      }}>
                        {submittingBids ? "Submitting..." : "Submit Bids"}
                      </MDButton>
                    )}
                  </div>
                )}
              </TableCell>
              <TableCell />
            </TableRow>
            {headerGroups.map((headerGroup, key) => (
              <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idx) => (
                  <DataTableHeadCell
                    key={idx}
                    {...column.getHeaderProps(isSorted && column.sort && column.getSortByToggleProps())}
                    width={column.width ? column.width : "auto"}
                    align={column.align ? column.align : "left"}
                    sorted={setSortedValue(column)}
                    column={column}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {page.length > 0 ? (
          <TableBody {...getTableBodyProps()}>
            {page.map((row, key) => {
              prepareRow(row)
              return (
                <React.Fragment key={key}>
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell, idx) => (
                      <DataTableBodyCell
                        key={idx}
                        noBorder={noEndBorder && rows.length - 1 === key}
                        align={cell.column.align ? cell.column.align : "left"}
                        column={cell.column}
                        row={cell?.row?.original}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </DataTableBodyCell>
                    ))}
                  </TableRow>
                  <DataTableBodyCollapse
                    tagFilter={tagFilter}
                    index={row.id}
                    row={row}
                    isTargetRanking={isTargetRanking}
                    setChangedMaxBids={setChangedMaxBids}
                  />
                </React.Fragment>
              )
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={12} align="center">
                <MDTypography variant="button" color="textSecondary">
                No results! Please try another search or select a new tag.
                </MDTypography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
        </Table>
        <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
          <DialogTitle textAlign="center" style={{ marginLeft: 40, marginRight: 40 }}>
            You are about to submit {facilityData.length} {facilityData.length > 1 ? 'bids' : 'bid'} to SpareFoot. Are you sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{color: 'red'}} align="center">
              {countBidAboveLimit}  {countBidAboveLimit.length > 1 ? 'Bids' : 'Bid'} above Portfolio limit
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "space-around" }}>
            <MDButton variant="outlined" color="error" onClick={handleSubmit}>
              Yes
            </MDButton>
            <MDButton
              variant="contained"
              style={{ color: "white", background: "grey" }}
              onClick={() => setModalOpen(false)}
            >
              No
            </MDButton>
          </DialogActions>
        </Dialog>
      </TableContainer>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" px={3}  sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderTop: `${borderWidth[1]} solid ${light.main}`,
      })}>
        {entriesPerPage && (
          <MDBox display="flex" alignItems="center">
            <Autocomplete
              disableClearable
              value={pageSize.toString()}
              options={entries}
              onChange={(event, newValue) => {
                newValue = parseInt(newValue, 10)
                setEntriesPerPage(newValue)
                onEntriesChange(newValue)
              }}
              size="small"
              sx={{ width: "5rem" }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                />
              )}
            />
            <MDTypography variant="caption" color="secondary">
              &nbsp;&nbsp;entries per page
            </MDTypography>
          </MDBox>
        )}
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="end"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {showTotalEntries && (
            <MDBox mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="secondary" fontWeight="regular">
                Showing {entriesStart} to {entriesEnd} of {rows.length} entries
              </MDTypography>
            </MDBox>
          )}
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="100%" mx={1}>
                  <MDInput
                    inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={(handleInputPagination, handleInputPaginationValue)}
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      </MDBox>
    </Paper>
  )
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: true,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;