import React, { useEffect, useContext } from 'react';
import { AuthContext } from '../../context/authContext';


const Signout = () => {
  const { logout } = useContext(AuthContext);
  useEffect(() => {
    logout()
    window.location.href = '/sign-in'
  }, [])

  return (<div></div>);
}

export default Signout